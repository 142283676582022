import React, { useState } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Layout } from "../../components/layout"
import { GridTwo, HorSepAnim } from "../../components/grids"
import { H2UppercaseGrey,
         H4UppercaseGrey,
         H3Grey,          } from "../../components/headings"

import { Carousel,
         CarouselItem,
         CarouselControl,
         CarouselIndicators,
         CarouselCaption,    } from 'reactstrap';

import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import  styled  from "styled-components"

export default function About({ data }) {
    const options = {
        renderNode: {
            [BLOCKS.HEADING_2]: (node, children) => <H2UppercaseGrey>{children}</H2UppercaseGrey>,
            [BLOCKS.HEADING_3]: (node, children) => <H3Grey>{children}</H3Grey>,
            [BLOCKS.HEADING_4]: (node, children) => <H4UppercaseGrey>{children}</H4UppercaseGrey>,
        }
    }
    const aboutData = data.content.nodes[0];

    const imgs = data.company.nodes.map(elem => {
        return (
            <Img fluid={elem.childImageSharp.fluid} />
        )
    })

    // Build slider
    const [activeIndex, setActiveIndex] = useState(0);
    const cmsSlides = aboutData.images1.map((item) => {
        return (
            <CarouselItem style={{ height: "100vh", maxHeight: "750px" }}>
                <Img fluid={item.fluid} style={{ height: "100vh", maxHeight: "750px" }}  />
                <CarouselCaption captionText={item.description} captionHeader={item.title} />
            </CarouselItem>
        );
    })
    const cmsSlideLength = cmsSlides.length;
    const next = () => {
        const nextIndex = activeIndex === cmsSlideLength - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }
  
    const previous = () => {
        const nextIndex = activeIndex === 0 ? cmsSlideLength - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }
  
    const goToIndex = (newIndex) => {
        setActiveIndex(newIndex);
    }

    const TextDiv = styled.div`
        padding: 80px;
        text-align: left;

        @media screen and (max-width: 813px) {
            text-align: left;
            padding: 30px;
            font-size: 0.9rem;
        }
    `

    return (
        <Layout title="About us" lang="en" actSite="about">
            <HorSepAnim height="250px" images={imgs} />
            <GridTwo left={
                <TextDiv>
                    {documentToReactComponents(aboutData.text1.json, options)}
                </TextDiv>
            }
            right={
                <Carousel
                    activeIndex={activeIndex}
                    next={next}
                    previous={previous}
                    interval={10000}
                    style={{ height: "100vh", maxHeight: "750px"}}
                >
                    <CarouselIndicators items={aboutData.images1} activeIndex={activeIndex} onClickHandler={goToIndex} />
                    {cmsSlides}
                    <CarouselControl direction="prev" directionText=" " onClickHandler={previous} />
                    <CarouselControl direction="next" directionText=" " onClickHandler={next} />
                </Carousel>
            }/>
            <GridTwo 
            left={
                <div style={{ height: "100vh", maxHeight: "600px"}}>
                    <iframe
                        title="Company Video"
                        width="100%"
                        height="100%"
                        src="https://www.youtube.com/embed/gOn35eahY5w?autoplay=1&mute=1&loop=1&controls=0&showinfo=0&modestbranding=1&playlist=gOn35eahY5w"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
            }
            right={
                <TextDiv>
                    {documentToReactComponents(aboutData.text2.json, options)}
                </TextDiv>
            }/>
            <GridTwo left={
                <TextDiv>
                    {documentToReactComponents(aboutData.text3.json, options)}
                </TextDiv>
            }
            right={
                <Img fluid={aboutData.image3.fluid} style={{ height: "100%", width: "100%", maxWidth: "900px", float: "right"}}/>
            }/>
        </Layout>
    )
}

// Query pictures for lazy loading
export const query = graphql`
    query {
        sidePic: file(relativePath: { eq: "schueco_side.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1150, webpQuality: 100, jpegQuality: 100, pngQuality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        company: allFile(filter: {relativeDirectory: {eq: "company"}}) {
            nodes {
                childImageSharp {
                    fluid(maxWidth: 1150, webpQuality: 100, jpegQuality: 100, pngQuality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
        content: allContentfulAboutUs(filter: {node_locale: {eq: "en"}}) {
            nodes {
                image3 {
                    fluid(maxWidth: 1200, quality: 100) {
                        src
                        ...GatsbyContentfulFluid
                    }
                }
                images1 {
                    fluid(maxWidth: 1200, quality: 100) {
                        src
                        ...GatsbyContentfulFluid
                    }
                    title
                    description
                }
                text1 {
                    json
                }
                text2 {
                    json
                }
                text3 {
                    json
                }
            }
        }
    }
`